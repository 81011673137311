import moment from 'moment';

export const REFRESH_AMOUNT = 120;
export const INTERVAL_MILLISECONDS = 30000;

export const getIntervalDates = (interval) => {
    const { definedInterval, from: _intervalFrom } = interval;
    const intervalFrom = moment(_intervalFrom);
    let from = moment();
    let to = moment();
    const labels = [];

    if (definedInterval?.toLowerCase() === 'lasthour') {
        from = from.subtract(1, 'hours');
        labels.push(`Time: ${from?.format('HH:mm')} - ${to?.format('HH:mm')}`);
    } else if (definedInterval?.toLowerCase() === 'today') {
        from = from.startOf('day');
        labels.push(`Time: ${from?.format('HH:mm')} - ${to?.format('HH:mm')}`);
    } else if (definedInterval?.toLowerCase() === 'thisweek') {
        from = from.startOf('week');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval?.toLowerCase() === 'thismonth') {
        from = from.startOf('month');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval?.toLowerCase() === 'thisquarter') {
        from = from.startOf('quarter');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval?.toLowerCase() === 'thisyear' || definedInterval?.toLowerCase() === 'all') {
        from = from.startOf('year');
        labels.push(`Time: ${from?.format('DD.MM.YYYY')} - ${to?.format('DD.MM.YYYY')}`);
    } else if (definedInterval?.toLowerCase() === 'lastmonth') {
        from = from.subtract(1, 'month').startOf('month');
        to = to.subtract(1, 'month').endOf('month');
        labels.push('Time: Last month');
    } else if (definedInterval?.toLowerCase() === 'lastweek') {
        from = from.subtract(1, 'week').startOf('week');
        to = to.subtract(1, 'week').endOf('week');
        labels.push('Time: Last week');
    } else if (definedInterval?.toLowerCase() === 'customYear') {
        from = moment(intervalFrom).startOf('year');
        to = moment(intervalFrom).endOf('year');
        labels.push(`Year: ${intervalFrom?.format('YYYY')}`);
    } else if (definedInterval?.toLowerCase() === 'custommonth') {
        from = moment(intervalFrom).startOf('month');
        to = moment(intervalFrom).endOf('month');
        labels.push(`Month: ${intervalFrom?.format('MMM-YYYY')}`);
    } else if (definedInterval?.toLowerCase() === 'custom') {
        from = moment(interval.from);
        to = moment(interval.to);
        labels.push(`Time: ${from?.format('DD.MM.YYYY HH:mm')} - ${to?.format('DD.MM.YYYY HH:mm')}`);
    }
    return { from, to, labels };
};

export const updateRequestSettings = (interval, requestSettings) => {
    const { from, to } = getIntervalDates(interval);

    if (requestSettings?.params?.from > 0) {
        return { ...requestSettings, params: { ...requestSettings?.params, from: from.valueOf(), to: to.endOf('second').valueOf() } };
    }
    return requestSettings;
};