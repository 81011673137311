import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    chartContainer: {
        minHeight: 0,
        display: 'flex',
        flex: 1,
        minWidth: 0,
        maxWidth: '100%',
        position: 'relative'
    }
}));

function BarChart({ data: { byTimestamp, total }, showBy, groupBy }) {
    const classes = useStyles();
    const theme = useTheme();
    const barThickness = 15;
    const maxBarThickness = 12;
    const stacked = false;
    const cornerRadius = 3;
    let yMax;
    if (showBy === 'percentage') {
        yMax = 100;
    }

    const getLabelCount = (msgType) => {
        if (showBy === 'percentage') {
            if (!total?.Total) {
                return '0%';
            }

            const msgTypeCount = total?.[msgType] ?? 0;
            const percentage = (msgTypeCount / total.Total) * 100;
            return `${percentage.toFixed(1)}%`;
        }
        return byTimestamp?.[msgType]?.reduce((acc, current) => acc + current.y, 0) ?? 0;
    };

    const data = {
        datasets: [
            {
                label: `SMS - ${getLabelCount('SMS')}`,
                tooltipLabel: 'SMS',
                backgroundColor: theme.palette.channel.sms.main,
                borderColor: 'transparent',
                data: byTimestamp.SMS,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                hidden: !(total?.SMS > 0)
            },
            {
                label: `Whatsapp - ${getLabelCount('WhatsApp')}`,
                tooltipLabel: 'Whatsapp',
                backgroundColor: theme.palette.channel.whatsapp.main,
                borderColor: 'transparent',
                data: byTimestamp.WhatsApp,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                hidden: !(total?.WhatsApp > 0)
            },
            {
                label: `Messenger - ${getLabelCount('Messenger')}`,
                tooltipLabel: 'Messenger',
                backgroundColor: theme.palette.channel.messenger.main,
                borderColor: 'transparent',
                data: byTimestamp.Messenger,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                hidden: !(total?.Messenger > 0)
            },
            {
                label: `Instagram - ${getLabelCount('Instagram')}`,
                tooltipLabel: 'Instagram',
                backgroundColor: theme.palette.channel.instagram.main,
                borderColor: 'transparent',
                data: byTimestamp.Instagram,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                hidden: !(total?.Instagram > 0)
            },
            {
                label: `RCS - ${getLabelCount('RCS')}`,
                tooltipLabel: 'RCS',
                backgroundColor: theme.palette.channel.rcs.main,
                borderColor: 'transparent',
                data: byTimestamp.RCS,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
                hidden: !(total?.RCS > 0)
            },
            {
                label: `Total - ${showBy === 'percentage' ? '100%' : getLabelCount('Total')}`,
                tooltipLabel: 'Total',
                backgroundColor: theme.palette.primary.main,
                borderColor: 'transparent',
                data: byTimestamp.Total,
                barThickness,
                maxBarThickness,
                barPercentage: 0.5,
                categoryPercentage: 0.5
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cornerRadius,
        animation: {
            duration: 500,
            easing: 'linear'
        },
        legend: {
            display: true
        },
        layout: {
            padding: 0
        },
        scales: {
            xAxes: [
                {
                    offset: true,
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: groupBy,
                        displayFormats: {
                            minute: 'mm',
                            hour: 'HH',
                            day: 'DD.MM.YYYY',
                            week: '[w]-w',
                            month: 'MMM-YY',
                            quarter: '[Q]Q-YY',
                            year: 'YYYY',
                        }
                    },
                    bounds: 'ticks',
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        source: 'auto',
                        padding: 20,
                        fontColor: theme.palette.text.secondary
                    },
                    stacked
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    },
                    ticks: {
                        padding: 20,
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                        max: yMax,
                        maxTicksLimit: 5
                    },
                    stacked
                }
            ]
        },
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.common.white,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary,
            callbacks: {
                title: () => { },
                label: (tooltipItem) => {
                    const label = `${data.datasets[tooltipItem.datasetIndex].tooltipLabel}: ${showBy === 'percentage' ? tooltipItem.yLabel?.toFixed(1) : tooltipItem.yLabel}`;
                    return label;
                }
            }
        }
    };

    return (
        <div className={classes.chartContainer}>
            <Bar
                data={data}
                options={options}
            />
        </div>
    );
}

BarChart.propTypes = {
    data: PropTypes.object.isRequired,
    showBy: PropTypes.string,
    groupBy: PropTypes.string
};

export default BarChart;