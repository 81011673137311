import React from 'react';
import moment from 'moment';

const defaultData = { byTimestamp: {}, total: { SMS: 0, WhatsApp: 0, Messenger: 0, Instagram: 0, RCS: 0, Total: 0 } };

const useTwoWayMessageBarData = (statistics, groupBy, visualization) => {
    const [dataByTimestamp, setDataByTimestamp] = React.useState({});
    const [total, setTotal] = React.useState({ SMS: 0, WhatsApp: 0, Messenger: 0, Instagram: 0, RCS: 0, Total: 0 });

    React.useEffect(() => {
        let mounted = true;

        const resp = statistics?.reduce?.((acc, current) => {
            const _key = moment(current?.groupingStart).startOf(groupBy).toJSON();

            const smsAmount = current?.inboundMessageCounts?.SMS ?? 0;
            const whatsappAmount = current?.inboundMessageCounts?.Whatsapp ?? 0;
            const messengerAmount = current?.inboundMessageCounts?.Messenger ?? 0;
            const instagramAmount = current?.inboundMessageCounts?.Instagram ?? 0;
            const rcsAmount = current?.inboundMessageCounts?.RCS ?? 0;

            return {
                ...acc,
                byTimestamp: {
                    ...acc.byTimestamp,
                    [_key]: {
                        SMS: (acc?.byTimestamp?.[_key]?.SMS ?? 0) + smsAmount,
                        WhatsApp: (acc.byTimestamp?.[_key]?.WhatsApp ?? 0) + whatsappAmount,
                        Messenger: (acc.byTimestamp?.[_key]?.Messenger ?? 0) + messengerAmount,
                        Instagram: (acc.byTimestamp?.[_key]?.Instagram ?? 0) + instagramAmount,
                        RCS: (acc.byTimestamp?.[_key]?.RCS ?? 0) + rcsAmount,
                        Total: (acc.byTimestamp?.[_key]?.Total ?? 0) + smsAmount + whatsappAmount + messengerAmount + instagramAmount + rcsAmount
                    }
                },
                total: {
                    SMS: acc.total.SMS + smsAmount,
                    WhatsApp: acc.total.WhatsApp + whatsappAmount,
                    Messenger: acc.total.Messenger + messengerAmount,
                    Instagram: acc.total.Instagram + instagramAmount,
                    RCS: acc.total.RCS + rcsAmount,
                    Total: acc.total.Total + smsAmount + whatsappAmount + messengerAmount + instagramAmount + rcsAmount
                }
            };
        }, defaultData) ?? defaultData;

        const getProperty = (propName) => {
            const formatData = (data, total) => {
                switch (visualization) {
                    case 'percentage': {
                        return (data / total) * 100;
                    }
                    default: {
                        return data;
                    }
                }
            };

            return Object.keys(resp?.byTimestamp ?? {}).map((key) => ({ x: key, y: formatData(resp?.byTimestamp?.[key]?.[propName], resp?.byTimestamp?.[key]?.Total) }));
        };

        if (mounted) {
            setDataByTimestamp({
                SMS: getProperty('SMS'),
                WhatsApp: getProperty('WhatsApp'),
                Messenger: getProperty('Messenger'),
                Instagram: getProperty('Instagram'),
                RCS: getProperty('RCS'),
                Total: getProperty('Total')
            });
            setTotal(resp.total);
        }
        return () => { mounted = false; };
    }, [statistics, groupBy, visualization]);

    return { byTimestamp: dataByTimestamp, total };
};

const useTwoWayPollStatistics = (serviceKeywords, messageStatistics, ivrStatistics) => {
    const [pollStatistics, setPollStatistics] = React.useState([]);

    React.useEffect(() => {
        // get available canidates from service keywords
        let availableCandidates = serviceKeywords?.availableKeywords?.filter?.((k) => k.parentKeywordId !== null)?.map((k) => {
            return { candidate: k?.text, messages: 0, ivr: 0 };
        }) ?? [];

        // add available candidates from IVR feed
        if (ivrStatistics?.length > 0) {
            availableCandidates = availableCandidates?.concat(ivrStatistics?.map((i) => { return { candidate: i?.candidate, messages: 0, ivr: 0 }; }));
        }

        // remove duplicates and sort candidates
        const seen = new Set();
        availableCandidates = availableCandidates?.filter?.((el) => {
            const duplicate = seen.has(el.candidate);
            seen.add(el.candidate);
            return !duplicate;
        })?.sort((a, b) => {
            return a.candidate.localeCompare(b.candidate, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });

        const totMessages = messageStatistics?.filter?.((s) => availableCandidates?.some((a) => a?.candidate === s?.candidate))?.reduce?.((prev, curr) => { return prev + curr.messages; }, 0) ?? 0;
        const totIvr = ivrStatistics?.reduce?.((prev, curr) => { return prev + curr.ivr; }, 0) ?? 0;

        // set poll statistics
        const pollStats = availableCandidates?.map?.((c) => {
            const candidate = c?.candidate;
            const messagesAmount = messageStatistics?.find((m) => m.candidate.toLowerCase() === candidate.toLowerCase())?.messages ?? 0;
            const ivrAmount = ivrStatistics?.find((m) => m.candidate.toLowerCase() === candidate.toLowerCase())?.ivr ?? 0;

            const messagesPercentage = ((messagesAmount / (totMessages)) * 100).toFixed(1);
            const ivrPercentage = ((ivrAmount / (totIvr)) * 100).toFixed(1);

            const messagesTotalPercentage = ((messagesAmount / (totMessages + totIvr)) * 100).toFixed(1);
            const ivrTotalPercentage = ((ivrAmount / (totMessages + totIvr)) * 100).toFixed(1);

            return {
                candidate,
                messages: messagesAmount,
                ivr: ivrAmount,
                messagesPercentage,
                ivrPercentage,
                messagesTotalPercentage,
                ivrTotalPercentage
            };
        }) ?? [];
        setPollStatistics(pollStats);
    }, [serviceKeywords, messageStatistics, ivrStatistics]);
    return pollStatistics;
};

export {
    useTwoWayMessageBarData,
    useTwoWayPollStatistics
};